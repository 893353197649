.acceptPayment {
    background-color: #E89532;
    outline: none;
    border: none;
    color: white;
    padding: 8px 15px;
    border-radius: 4px;

    cursor: pointer;
}

.vat {
    color: green;
}

.discount {
    color: red;
}