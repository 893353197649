.heading {
    font-weight: 500;
    font-size: 20px;
}

.promotionName {
    font-size: 18px;
    font-weight: 600;
    margin: 28px 0 0;
}

.promotionDescription {
    font-size: 14px;
}

.promotionCard {
    background: #fff;
    border: 1px solid #ddd;
    padding: 14px 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid transparent;
    box-shadow: 0px 0px 12px 1px rgba(232, 223, 232, 1);
    margin-bottom: 12px;
}

.mainTabHead {
    font-weight: 600;
    font-size: 17px;
}

.button-prom button {
    background: #e89532;
    color: #fff;
    border: 0;
    font-weight: 600;
    padding: 11px 29px;
}

.promotionCard div {
    width: 25%;
    float: left;
}

.button-prom {
    text-align: right;
}