.paid {
    background-color: #e89532;
    padding: 6px 20px;
    font-size: 13px;
    border-radius: 6px;
    color: white;
    margin-bottom: 0;
    width: auto;
    margin: 0 auto;
    display: inline;
}

.pay {
    background-color: green;
    padding: 6px 20px;
    font-size: 13px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    margin-bottom: 0;
    width: auto;
    margin: 0 auto;
    display: inline;
}