.subLabel {
    font-size: 14px;
}

.heading {
    font-size: 20px;
    font-weight: 600;
}

.label {
    font-size: 16px;
    font-weight: 500;
}