.propertyFeature {
    /* border: 1px solid #5f5f5f; */
    padding: 14px;
    border-radius: 10px;
    height: 220px;
    box-shadow: 0 0 10px 1px #adadad96;
}

.propertyFeature ul {
    padding: 0;
}

.propertyFeature ul li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding-right: 20px;
    font-size: 14px;
    justify-content: space-between;
}

.main-room-slider .slick-list {
    padding: 11px 10px 10px 10px;
}

.propertyFeature ul li span {
    display: flex;
}

.propertyFeature ul li div {
    display: flex;
    align-items: center;
}

.propertyFeature ul li li span {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.main-room-slider.cursor-pointer .slick-track {
    padding: 7px;
}

.propertyFeature img {
    max-width: 21px;
    margin-right: 10px;
    border-radius: 4px;
    max-height: 29px;
    object-fit: contain;
}

.modal-header {
    border: 0;
    text-align: center;
}

.modal-header h5.modal-title {
    margin: 0 auto;
    display: table;
    width: 100%;
}

.modal-header button {
    position: absolute;
    left: 18px;
    color: #000;
    opacity: 1;
}

.amenitiesCheckbox {
    position: relative;
    appearance: none;
    width: 100px;
    height: 50px;
    background: #ccc;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
}

.bedIcon {
    width: 40px;
    height: 40px;
}

.propertyFeature p {
    font-size: 14px;
}

.iconCount {
    position: fixed;

}

.modal-header {
    border: 0;
}

.modal-header h5.modal-title {
    margin: 0 auto;
    display: table;
    width: 100%;
    text-align: center;
}

ul.slick-dots {
    padding-right: 15px;
}

.modal-header button {
    position: absolute;
    opacity: 1;
}

.room {
    max-width: 60px !important;
    height: 45px;
}