.heading {
    margin-bottom: 0;
    align-self: center;
    font-size: 20px;
    font-weight: 600;
}

.noPromotion {
    font-weight: 600;
    font-size: 15px !important;
}