.bookingNo {
    cursor: pointer;
    color: #E89532;
}


.tab {
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #e89532;
}

.tab:hover {
    font-weight: 400;
    background-color: white;
    border: 1px solid #E89532;
    border-radius: 5px;
}

.activeTab {
    background-color: #E89532;
    border-bottom: 1px solid #E89532;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
}

.tabPanel {
    display: flex;
    background-color: #f9f7f7;
    border-radius: 5px;
}

.totalAmount {
    font-weight: 700;
}

.totalAmountTab {
    background-color: #f9f7f7;
}