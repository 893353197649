@media (max-width:1400px)
{
    .login-page-right {
        padding-left: 0px;
    }
    .copy-right-lo p {
        font-size: 11px !important;
    }
    .copy-right-lo ul li a {
        font-size: 11px;
    }
    /* .side-bar ul li {
        padding: 18px 8px 18px 17px;
    } */
    .side-bar ul li i {
        margin-right: 8px;
        font-size: 16px;
    }
    .side-bar ul li a {
        font-size: 14px;
    }
    .side-bar ul li {
        font-size: 14px;
    }
    .left-sidebar {
        width: 19%;
    }
    li.down-arow::before {
        width: 8px;
        height: 8px;
        right: 9px;
        top: 23px;
    }
    .right-sidebar {
        width: 80%;
    }
    .inner-tabs h5 {
        font-size: 16px;
        max-width: 160px;
    }
    .pie-chart-left h3, .pie-chart-right h3 {
        font-size: 16px;
    }
    body h3 {
        font-size: 16px;
    }
    .listing-table table tr th {
        font-size: 13px;
        padding: 14px 9px;
    }
    .listing-table table tr td {
        font-size: 12px;
        padding: 12px 9px;
    }
    .card-shadow {
        padding: 14px;
    }   
    .filter-main input {
        min-width: 270px;
        font-size: 14px;
    }
}
@media (max-width:991px)
{
    .left-sidebar {
        width: 39%;
        position: fixed;
        left: 0;
        z-index: 1;
        top: 0;
    }
    .right-sidebar {
        width: 100%;
    }
    span.side-br-mobile {
        display: block;
      }
    .main-dashboard .col-md-7 {
        width: 100%;
    }
    .main-dashboard .col-md-5 {
        width: 100%;
    }
    .login-page {
        background: none;
    }
    .login-page::before {
        opacity: 0;
    }
    .login-page-right form {
        margin: 0 auto;
    }
    .login-page-right h5, .login-page-right p {
        text-align: center;
    }
    .logo {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 21px 0;
    }
    span.side-br-mobile {
        color: #e89532;
        font-size: 22px;
    }
    #top-bar-main {
        padding: 10px 20px;
        margin-bottom: 30px;
    }
    .pie-chart .col-md-4, .table-chart .col-md-4 {
        width: 100%;
        margin-bottom: 25px;
    }
    .table-chart {
        padding-top: 15px;
    }
    .left-top-bar h4 {
        margin-left: 6px;
        font-size: 15px;
    }
    #top-bar-main .col-md-6:first-child {
        width: auto;
    }
    #top-bar-main .col-md-6:last-child {
        width: 50%;
    }
    .listing-table table {
        width: 140%;
    }
    .listing-table table tr th:first-child {
        width: 62px;
    }
    .listing-table-scrl {
        overflow-x: auto;
        width: 100%;
    }
    .add-property-sc .form-group.add-image.new-add-gallery span {
        top: 6px;
        margin-bottom: 32px;
    }
    .add-property-sc .form-group.add-image.new-add-gallery input {
   
        top: 33px;
    }
    .table-chart-right.card-shadow table, .table-chart-left.card-shadow table {
        width: 100%;
    }
}
@media (max-width:767px)
{
    .left-sidebar {
        width: 100%;
    }
    .dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content {
        min-width: 100%;
    }
    body .w-75 {
        width: 100% !important;
    }
    .form-group.description div {
        width: 100%;
    }
    .style_featuresBody__mt44i {
        width: 100%;
    }
    .locaton-card p {
        font-size: 13px;
    }
    .dasboad-property-detail .modal-dialog.modal-dialog-centered {
        width: 95%;
    }
    div#calendar-price .col-md-6.form-group.d-flex.justify-content-between.align-items-center {
        margin: 4px 0;
    }
    .rf-left h6 {
        font-size: 12px;
    }
    .side-left img {    
        height: 220px;
    }
    ul.car-amnites.aminity-wh-img {
        width: initial;
    }
    .rf-left span {
        font-size: 12px;
    }
    .side-left {
        padding-right: 9px;
    }
    .side-sider {
        padding-left: 0;
    }
    .dasboad-property-detail .modal-body {
        max-height: 400px;
    }
    .form-group.description .h-100.wmde-markdown-var.w-md-editor.w-md-editor-show-edit {
        width: 100%;
    }
    .side-sider {
        width: 20%;
        padding-left: 0;
    }
    .show-all {
        bottom: 9px;
        left: 0px;
        width: 100%;
        min-height: 68px;
        right: 0;
    }
    .refrela .col-md-6 {
        width: 50%;
    }
    .inner-tab-deisign .bed-rooms ul {
        flex-wrap: wrap;
    }
    .show-all p {
        font-weight: 500;
        font-size: 13px;
    }
    .pro-featu ul li {
        margin-right: 13px;
        font-size: 16px;
    }
    .pro-featu ul li:last-child {
        margin: 0;
    }
    .pro-featu ul li::before {
        right: -9px;
    }
    .side-sider img {
        margin-bottom: 0px;
        height: 68px;
    }
    ul.amnites.aminity-wh-img {
        display: flex;
        width: auto;
    }
    .inner-tab-deisign .bed-rooms ul li img {
        max-width: 23px;
        opacity: 0.8;
        margin-right: 0;
        max-height: 20px;
        object-fit: contain;
        border-right: 0;
        padding-right: 0;
        min-height: 22px;
        margin-bottom: 2px;
    }
    ul.amnites.aminity-wh-img li, ul.car-amnites.aminity-wh-img li {

    width: 100%;
    font-size: 14px;
}
ul.amnites.aminity-wh-img li img {
    height: 39px;
    object-fit: contain;
}
    .inner-tab-deisign .bed-rooms ul li {
      
        margin: 0 5px 0 0;  
        font-size: 13px;
        width: 31.5%;
        padding: 9px 5px;
        flex-direction: column;
    }
    .filter-left {
        flex-wrap: wrap;
    }
    .right-sidebar {
        padding-left: 12px;
        padding-right: 11px;
        height: auto;
        overflow: initial;
    }
    div#map-show iframe {
        height: 340px;
    }
    ul.amnites.aminity-wh-img {
        grid-gap: 3px 12px;
    }
    .filter-main input, .filter-pro input {
        width: 100%;
        margin: 0 0 10px !important;
        min-width: initial;
    }
    .filter-left select {
        margin: 0 0 9px !important;
    }
    .vendor-listing {
        max-width: 98%;
    }
    .filter-left select, .filter-pro select {
        width: 100%;
        margin: 0 0 9px !important;
    }
    .style_bookingCard__2x4c6 {
        padding: 0px 14px !important;
    }
    .gray--first p {
        font-size: 12px;
    }
    .vendor-listing i {
        font-size: 12px;
        margin: 0 4px 0 0;
    }
    .gray-clr {
        padding: 8px 15px;
        background: #FFECD5;
    }
    .gray-clr p {
        font-size: 15px;
    }
    /* .togglr-btn span:first-child {
        display: none;
    } */
    .togglr-btn i {
        margin: 0 4px;
        font-size: 19px;
    }
    .pie-chart .col-md-4, .table-chart .col-md-4 {
        margin-bottom: 2px;
    }
    .logout a {
        font-size: 0;
    }
    .user-profile span {
        font-size: 15px;
        width: 60px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .right-top-bar {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .notfication i, .logout i {
        font-size: 18px;
    }

    .user-profile img {
        max-width: 33px;
        max-height: 33px;
        min-width: 33px;
        min-height: 33px;
    }
    .notfication {
        margin-left: 10px;
        margin-right: 19px;
    }
    .right-top-bar .user-profile {
        display: flex;
        align-items: center;
    }
    .card-shadow {
        margin: 10px 0;
    }
    .proerty-tabs.mt-4 {
        margin: 0 !important;
    }
    .table-chart-right.card-shadow {
        height: auto;
    }
    .pie-chart {
        padding-top: 11px;
    }
    #top-bar-main .col-md-6:last-child {
        width: 50%;
    }
    #top-bar-main .col-md-6:first-child {
        width: 50%;
    }
    .togglr-btn {
        display: flex;
        align-items: center;
    }
    #top-bar-main {
        padding: 10px 0px;
    }
    .left-top-bar h4 {
        font-size: 13px;
    }
    .listing-table table {
        width: 270%;
    }
    .vendor-listing ul li {
        margin: 0 9px 0 0;
    }
    ul.paginaion li::before {
        left: -13px;
        top: 9px;
    }
    .pagination>li>a {
        width: 36px;
        height: 36px;
    }
    .pagination>li>a {
        padding: 5px 7px;
    }
    ul.paginaion li a {
        font-size: 13px;
    }
    .listing-table table tr th:first-child {
        width: 52px;
    }
    .vendor-listing a.cm-btn, a.cm-btn {
        font-size: 14px;
        padding: 11px 16px;
    }
    .bathroom-select h5 {
        font-size: 16px;
    }
    .add-property-sc .form-group label {
        font-size: 14px;
    }
    .add-button {
        font-size: 15px;
    }
    .ficed-top-nbar {
        flex-wrap: wrap;
    }
    .calander-outer h5 {
        font-size: 17px;
    }
    .ficed-top-nbar .d-inline-flex.align-items-center {
        flex-wrap: wrap;
        width: 100%;
    }
    .ficed-top-nbar .d-inline-flex.align-items-center div {
        width: 100%;
        margin: 1px 0 !important;
    }
    .cat-prop, .cat-prop button, .ficed-top-nbar input {
        width: 100%;
    }
    span.sech-btn {
        border-radius: 100px;
        width: 100%;
        margin: 7px 0 13px;
    }
    .copy-right-lo {
        display: none;
    }
    .side-bar ul li {
        padding: 8px 50px 0px 16px;
    }
    .side-bar ul li a {
        padding: 9px 15px;
    }
    .side-bar ul li.down-arow {
        padding: 24px 20px 15px 35px;
    }
    li.down-arow::before {
        right: 19px;
    }   
    .app.active .left-sidebar {
        width: 0;
    }
    .app.active .right-sidebar {
        width: 100%;
    }
    .inner-tabs h5 {
        max-width: 100%;
    }
    .table-chart .listing-table-scrl {
        height: auto;
    }
    .notification {
        right: -30px;
        min-width: 360px;
    }
    .chat-information {
        font-size: 14px;
    }
    .filter-pro {
        flex-wrap: wrap;
    }
    .filter-main {
        flex-wrap: wrap;
    }
    .filter-left .form-group, .filter-left {
        width: 100%;
    }
    .filter-main button {
        width: 100%;
    }
    .left-chat .fw-bold {
        font-size: 13px;
    }
    .left-chat li p.small.text-muted {
        font-size: 11px;
    }
}

