.categoryName {
    margin-bottom: 0px;
    font-weight: 700;
}

.rate,
.priceDate {
    font-size: 14px;
    margin-bottom: 0px;
}

.openCloseStatus {
    margin-bottom: 0px !important;
    font-size: 14px !important;
    margin-right: 3px !important;
}

.booked {
    background-color: #e2707069;
    padding: 5px 0px;
    padding-left: 5px;
    border-radius: 6px;
    color: #c95050;
    font-size: 11px;
    margin-bottom: 0px;
    font-weight: 500;
    letter-spacing: 1px;
    max-width: 100%;
    width: 100%;
}

.available {
    background-color: #52bd5275;
    padding: 5px 0px;
    padding-left: 5px;
    border-radius: 6px;
    color: #24a424;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.loadingCalendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 20vh);
}

.loadingCalendar p {
    font-size: 25px;
    color: #e89532;
    font-weight: 600;
}

.loadingCalendar img {
    width: 380px;
    height: 300px;
}

.selectedBox {
    border: 1px solid black;
};