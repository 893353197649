body {
    font-family: "Poppins", sans-serif !important;
}

* {
    outline: none;
}

body h3 {
    font-size: 18px;
    margin-bottom: 18px;
    font-weight: 500;
    color: #4e4e4e;
}

.bathroom-select h5 {
    width: auto;
    float: left;
    /* background: #fff; */
    padding: 0 20px 0 0;
    margin-bottom: 0px !important;
}

.bathrom-options h6 {
    font-size: 20px;
    margin: 6px 0 18px;
}

span.add-onother-nbed {
    color: #e89532;
    cursor: pointer;
}

span.add-onother-nbed i {
    margin-right: 5px;
}

.bathrom-options {
    background: #f3f3f3;
    padding: 14px;
    border-radius: 12px;
    margin: 9px 0 10px;
}

.inner-option .form-group input {
    width: auto;
    height: auto;
}

.inner-radio-option span {
    margin-right: 20px;
}

.main-dashboard {
    display: flex;
    height: 100vh;
    flex-wrap: wrap;
}

.bathroom-select hr {
    margin-bottom: 21px;
}

.error {
    color: red;
    font-size: 13px;
}

.left-sidebar {
    width: 17%;
    height: 100%;
    transition: 0.5s;
}

.login-page .form-group span {
    position: absolute;
    top: 13px;
    left: 25px;
}

.login-page .form-group label {
    position: absolute;
    top: 13px;
    right: 19px;
    cursor: pointer;
}

p.top-sign a {
    color: #db0605;
    text-decoration: none;
    font-weight: 700;
}

.login-page-right {
    padding-left: 70px;
}

.copy-right-lo p {
    font-size: 13px;
}

p.top-sign {
    position: absolute;
    top: 20px;
    right: 0;
    font-size: 17px;
    line-height: 22px;
    color: #000000;
    opacity: 1;
}

.copy-right-lo {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
}

.copy-right-lo p {
    font-size: 13px !important;
}

.copy-right-lo ul {
    padding: 0;
    margin-left: auto;
    display: flex;
}

.login-page::before {
    position: absolute;
    background: #e8953247;
    left: 0;
    top: 0;
    width: 52.3%;
    content: "";
    height: 100%;
}

.copy-right-lo ul li {
    margin-left: 10px;
    list-style: none;
}

.copy-right-lo ul li a {
    font-size: 13px;
    color: #231f20;
    opacity: 1;
    text-decoration: none;
}

.acount-profile-left h4,
.acount-profile-right h4 {
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 20px;
}

.table-chart-right.card-shadow {
    height: 100%;
    background: #f0f3f7;
}

.category-list ul {
    padding: 0;
    margin: 0;
}

.side-bar {
    height: 100vh;
    background: #fff;
    box-shadow: 0 0 10px 1px #ddddddbd;
    overflow-y: auto;
}

.category-list ul li span {
    margin-left: auto;
    float: right;
    color: #e89532;
    font-weight: 700;
}

.app.active .left-sidebar ul li.active a i {
    margin: 0;
}

.right-sidebar {
    width: 83%;
    transition: 0.5s;
    padding-left: 0;
    padding-right: 0px;
    padding-top: 13px;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 20px;
    background-color: #f4f4f487;
    padding: 20px 25px 20px;
}

.category-list ul li i {
    margin-right: 7px;
    color: #e89532;
    font-size: 13px;
}

.category-list ul li {
    list-style: none;
    border-bottom: 1px solid #eaeaea;
    padding: 13px 0;
    color: #929292;
}

.category-list ul li:last-child {
    border-bottom: 0;
}

.right-top-bar div {
    display: initial;
}

.right-top-bar {
    text-align: right;
}

canvas {
    width: 100%;
    height: 330px;
}

.pie-chart-left.card-shadow div,
.pie-chart-right.card-shadow div {
    height: 330px !important;
}

.side-bar ul {
    padding: 0;
    margin: 40px 0 0;
}

.proerty-tabs a {
    text-decoration: none;
}

.side-bar ul li {
    line-height: normal;
    text-align: left;
    padding: 8px 50px 0px 50px;
}

.side-bar ul li.down-arow {
    padding: 24px 20px 15px 65px;
}

.side-bar ul li a {
    color: #141A1E;
    text-decoration: none;
    transition: 0.5s;
    width: 100%;
    display: table;
    padding: 12px 15px;
    border-radius: 6px;
}

.side-bar ul li i {
    margin-right: 15px;
    font-size: 18px;
    color: #BEC3D6;
}

.side-bar ul li.active a i {
    color: #e89532;
}

.side-bar ul li.active a {
    color: #e89532;
    font-weight: 600;
    background: #e8953240;

}

.side-bar ul li:last-child {
    border-bottom: 0;
}

.logo img {
    max-width: 150px;
    transition: 0.5s;
}

.logo {
    text-align: center;
    padding-top: 31px;
    padding-bottom: 19px;
}

.user-profile img {
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
    object-fit: cover;
    border-radius: 100px;
    margin-left: 8px;
}

.inner-tabs {
    position: relative;
    background-color: white;
}

.card-shadow {
    padding: 30px;
    border-radius: 15px;
    display: table;
    width: 100%;
    background: #f0f3f7;
}

.togglr-btn i {
    margin: 0 10px;
    font-size: 28px;
    color: #e89532;
}

#top-bar-main {
    padding: 10px 25px 10px;
    /* box-shadow: 0 0 10px 1px #ddddddbd; */
    border-radius: 10px;
    margin-bottom: 40px;
}

.logout i {
    color: dimgrey;
    font-size: 22px;
    cursor: pointer;
}

.inner-tabs h4 {
    color: #282828;
    font-weight: bold;
}

.inner-tabs h5 {
    color: #2d2d2d;
}

.proerty-tabs .col-md-3:nth-child(2) .inner-tabs.card-shadow,
.proerty-tabs .col-md-3:nth-child(4) .inner-tabs.card-shadow {
    background: #FFECD5;
}

.proerty-tabs .col-md-4:nth-child(2) .inner-tabs.card-shadow,
.proerty-tabs .col-md-4:nth-child(4) .inner-tabs.card-shadow {
    background: #FFECD5;
}

.notfication {
    margin-left: 50px;
    position: relative;
    margin-right: 36px;
}

.notfication i {
    color: dimgrey;
    font-size: 22px;
}

.notfication label {
    position: absolute;
    top: -6px;
    right: -10px;
    background: #e89532;
    border-radius: 100px;
    color: #fff;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 10px;
}

.inner-tabs span {
    position: absolute;
    top: 50%;
    right: 21px;
    width: 60px;
    height: 60px;
    background: #e89532;
    color: #fff;
    border-radius: 100px;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    display: none;
}

/* .proerty-tabs .col-md-4:nth-child(1) .inner-tabs {
  background: #e89532;
} */
/* .proerty-tabs .col-md-4:nth-child(1) .inner-tabs h5,
.proerty-tabs .col-md-4:nth-child(1) .inner-tabs h4 {
  color: #fff;
} */
.pie-chart {
    padding-top: 40px;
}

/* .pie-chart-left::before,
.pie-chart-right::before {
  position: absolute;
  bottom: 20px;
  left: 19px;
  background: white;
  width: 76px;
  height: 13px;
  content: "";
  z-index: 9;
} */
.pie-chart-left,
.pie-chart-right {
    position: relative;
    background-color: white;
}

.left-top-bar {
    display: flex;
    align-items: center;
}

.left-top-bar h4 {
    margin-left: 23px;
    font-size: 18px;
    margin-bottom: 0;
    color: #636363;
}

.listing-table table tr.noresult td {
    padding: 15px 0;
    background: #efefef;
    font-size: 13px;
    text-align: center;
}

a.canvasjs-chart-credit {
    display: none;
}

.pie-chart-left h3,
.pie-chart-right h3 {
    font-size: 20px;
    margin-bottom: 18px;
    font-weight: 600;
    color: #4e4e4e;
}

.listing-table table {
    width: 100%;
}

.listing-table table tr td img {
    max-width: 57px;
    border-radius: 9px;
    height: 42px;
    width: 100%;
    object-fit: cover;
}

td.activebr span {
    background: #e89532;
    padding: 3px 10px;
    border-radius: 4px;
    color: #fff;
}

.listing-table table tr th {
    text-align: left;
    font-size: 14px;
    color: #000;
    font-weight: 600;
    padding: 14px 11px;
    border-bottom: 1px solid #EAECF0;
    background: #FCFCFD;
}

.vendor-listing tr td span i.fas.fa-trash-alt {
    color: #c71a1a;
}

.vendor-listing tr td span i.fas.fa-edit {
    color: #e89532;
}

td.inactive span {
    background: #ff000061;
    padding: 3px 10px;
    border-radius: 4px;
    color: #000;
}

.vendor-listing tr td span {
    margin-right: 14px;
}

.vendor-listing tr td img {
    width: 45px;
    max-width: 45px !important;
    min-height: 45px;
    min-width: 45px;
    max-height: 50px;
    border-radius: 150px !important;
}

.scroll-calander {
    display: flex;
    overflow-x: auto;
}

.outer-calander {
    width: 100%;
    min-width: 100%;
    padding-right: 26px;
}

.listing-table table tr td {
    font-size: 14px;
    padding: 12px 11px;
    border-bottom: 1px solid var(--gray-200, #EAECF0);
}

.table-chart {
    padding-top: 39px;
}

.listing-table h4 {
    font-size: 1rem;
    line-height: 1.625;
    color: #344767;
    margin-bottom: 15px;
}

.table-chart .listing-table-scrl {
    width: 100%;
    float: left;
    height: 350px;
    overflow-y: auto;
}

.app.active .logo img {
    max-width: 98px;
    transition: 0.5s;
}

.togglr-btn span {
    cursor: pointer;
}

.app.active .left-sidebar {
    width: 7%;
    transition: 0.5s;
}

.app.active .right-sidebar {
    width: 93%;
    transition: 0.5s;
}

.app.active .left-sidebar a {
    font-size: 0;
    transition: 0.5s;
}

/*********** Login Page***********/

.login-page {
    height: 100vh;
    background-image: url(../images/loginleft.jpg);
    background-position: top left -32px;
    background-size: 54% 100%;
    background-repeat: no-repeat;
}

.login-page-right {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.outre-calnder-grid {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
}

.dates-inner {
    display: flex;
    height: 100%;
}

.outre-calnder-grid-outer {
    display: flex;
    position: relative;
    padding-bottom: 8px;
    overflow-x: auto;
}

.day-cal span {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    width: 100%;
    display: table;
}

.empty-day-cal {
    margin-top: 8px;
}

.av-cal-list-grid__cell {
    border: 1px solid #e0e0e0;
    border-bottom-width: 0;
    border-left-width: 0;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    line-height: 1;
}

.av-cal-list-date {
    color: #6b6b6b;
}

.av-cal-list-date--weekend {
    font-weight: 700;
    color: #333;
}

.calander-outer {
    background: #f9f9f9;
    padding: 26px;
    border-radius: 10px;
}

.av-cal-list-cell {
    position: relative;
    background: #fff;
    text-align: right;
    padding: 14px 5px;
    min-width: 60px;
    line-height: 17px;
    border-top: 0;
}

.av-cal-list-date__day-number {
    display: block;
}

.av-cal-list-grid__cell--separator {
    border-right-width: 2px;
    border-right-color: gray;
}

.login-page-right h5 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 5px;
}

.side-sider .slick-list .slick-track {
    display: flex;
    flex-direction: column;
    width: 100% !important;
}

.side-sider .slick-list .slick-track div {
    width: 100% !important;
}

.main-sider {
    display: flex;
    flex-wrap: wrap;
}

.side-sider img {
    width: 100%;
    border-radius: 11px;
    margin-bottom: 13px;
    height: 137px;
    object-fit: cover;
}

/* .side-sider .slick-track {
  flex-direction: column;
} */
/* .side-sider .slick-track div {
  width: 100% !important;
} */
.side-left img {
    border-radius: 14px;
    width: 100%;
    height: 450px;
    object-fit: cover;
}

.side-left ul.slick-dots {
    display: none !important;
}

.more-hotos {
    position: absolute;
    bottom: 20px;
    background: #000000bf;
    width: 95%;
    min-height: 138px;
    border-radius: 10px;
    left: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.more-hotos p {
    margin: 0;
}

.side-sider {
    width: 20%;
    float: left;
    padding-left: 15px;
    position: relative;
}

.login-page .form-group img {
    max-width: 20px;
}

.side-left {
    width: 80%;
    float: left;
    padding-right: 15px;
}

th.answr {
    width: 750px;
}

.listing-table table tr td a,
.listing-table table tr td .action,
.search-button {
    /* background: #e89532; */
    color: #e89532;
    border-radius: 3px;
    text-decoration: none;
    margin: 6px 13px;
    cursor: pointer;
    font-size: 18px;
}

ul.paginaion li {
    margin: 0 33px 0 0;
    position: relative;
}

ul.paginaion li a {
    color: #8e8e8e;
    text-decoration: none;
}

ul.paginaion li::before {
    position: absolute;
    left: -22px;
    top: 6px;
    width: 8px;
    height: 8px;
    border: 1px solid #c6c6c6;
    content: "";
    border-top: 0;
    border-left: 0;
    transform: rotate(-45deg);
}

ul.paginaion li:first-child::before {
    opacity: 0;
}

ul.paginaion li.active a {
    color: #E89532;
    font-weight: 600;
}

.logout a i {
    margin-right: 5px;
}

.logout a {
    color: #000;
    text-decoration: none;
}

.form-group.add-image input {
    border: 0;
    height: 100px;
    width: 100px;
    clear: both;
    display: table;
    background: #fff;
    border: 0;
    font-size: 0;
    padding: 20px;
    position: absolute;
    left: 0;
    top: 42px;
}

.login-page-right p {
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
}

.login-page-right form {
    max-width: 395px;
}

.login-page-right form input {
    background: #f6f8fb;
    border-radius: 11.4826px;
    border: 0;
    width: 100%;
    height: 55px;
    padding: 0 58px;
}

.login-page-right form {
    padding-top: 10px;
    width: 100%;
    float: left;
}

.login-page .form-group {
    position: relative;
    margin-bottom: 16px;
}

.login-page .form-group a {
    font-size: 16px;
    line-height: 20px;
    color: #000;
    text-decoration: none;
}

.login-page .form-group button {
    background: #e89532;
    border-radius: 11.4826px;
    border: 0;
    width: 100%;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    margin-top: 28px;
    padding: 14px 0;
}

.edit-users-sc {
    box-shadow: 0 0 10px 1px #ddd;
    padding: 40px;
    max-width: 550px;
    margin: 0 auto;
    border-radius: 11px;
}

.edit-users-sc input,
.edit-users-sc select {
    width: 100%;
    height: 46px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 5px;
}

.edit-users-sc .form-group {
    margin-bottom: 15px;
}

.vendor-listing a.cm-btn:hover,
a.cm-btn:hover {
    color: #fff;
}

.form-group.add-image {
    position: relative;
}

.edit-users-sc .form-group label {
    margin-bottom: 3px;
    width: 100%;
}

td.action-btns {
    display: flex;
    padding: 17px 14px !important;
}

.form-group.add-image span {
    border: 1px dashed #ddd;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    top: 28px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    pointer-events: none;
    color: #e89532;
    background: #fff;
}

.vendor-listing a.cm-btn,
a.cm-btn {
    margin-left: auto;
    float: right;
    background: #e89532;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    padding: 13px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.edit-users-sc .form-group input[type="radio"] {
    width: auto;
    height: auto;
}

.verified {
    margin-bottom: 6px;
    color: #797979;
}

.edit-users-sc button {
    width: 100%;
    height: 42px;
    border: 0;
    color: #fff;
    background: #e89532;
    font-size: 19px;
    font-weight: 600;
    border-radius: 5px;
}

.slider-desc h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name.d-flex {
    align-items: center;
}

h5.loc {
    margin: 8px 0 19px;
    color: #4e4439;
    font-size: 110%;
    font-weight: 600;
    font-family: Campton-medium !important;
    align-items: center;
}

ul.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

ul.amnites li {
    list-style: none;
    margin-right: 39px;
    color: #4e4439;
    font-size: 16px;
    margin-bottom: 0;
    position: relative;
}

ul.amnites li:before {
    content: "";
    position: absolute;
    left: -21px;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #4e4439;
}

ul.amnites-faeture {
    padding: 0;
    display: flex;
    margin-bottom: 47px;
}

ul.amnites-faeture li {
    list-style: none;
    margin-right: 20px;
}

ul.amnites-faeture li span {
    background: #efe1d0;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5411764705882353);
    font-weight: 400;
    padding: 9px 20px;
}

.slider-desc p {
    margin-bottom: 30px;
}

ul.list-gyr li {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5686274509803921);
    margin-bottom: 5px;
    list-style: none;
}

ul.list-gyr {
    padding: 0;
    /* margin-bottom: 41px; */
}

ul.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

ul.amnites.aminity-wh-img {
    margin-bottom: 30px;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    grid-gap: 20px 40px;
    background: #f7f7f7;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 20px;
    position: relative;
    width: -webkit-max-content;
    width: max-content;
}

ul.car-amnites.aminity-wh-img {
    margin-bottom: 30px;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    grid-gap: 20px 25px;
    background: #f7f7f7;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;
    width: max-content;
    display: flex;
}

.pac-container {
    z-index: 9999;
}

input.pac-target-input {
    width: 100%;
}

ul.amnites li {
    list-style: none;
    margin-right: 39px;
    color: #4e4439;
    font-size: 16px;
    margin-bottom: 0;
    position: relative;
}

ul.amnites.aminity-wh-img li,
ul.car-amnites.aminity-wh-img li {
    color: #333;
    font-family: Campton-light;
    font-weight: 700;
    margin: 0;
    position: relative;
    grid-gap: 10px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    white-space: nowrap;
    width: 18%;
}

.listing-table {
    padding: 12px 0 5px;
}

.listing-table table {
    border-radius: 10px;
    margin-bottom: 28px;
}

ul.amnites li img,
ul.car-amnites li img {
    margin-right: 0px;
    max-width: 25px;
}

ul.amnites.aminity-wh-img.other-room,
ul.car-amnites.aminity-wh-img.other-room {
    grid-template-columns: 1fr 1fr 1fr;
}

ul.amnites li:first-child::before {
    display: none;
}

h5.loc img {
    margin-right: 10px;
}

ul.amnites.aminity-wh-img li::before,
ul.car-amnites.aminity-wh-img li::before {
    display: none;
}

div#map-show iframe {
    width: 100%;
    border-radius: 20px;
}

.add-property-sc {
    box-shadow: 0 0 10px 1px #ddd;
    padding: 40px;
    max-width: 1500px;
    margin: 0 auto;
    border-radius: 11px;
    background-color: white;
}

.add-property-sc input,
.add-property-sc select {
    width: 100%;
    height: 46px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 5px;
    padding: 0 20px;
}

.add-property-sc .form-group {
    margin-bottom: 15px;
}

.listing-table table tr td button {
    color: #ee5d5d;
    font-size: 18px;
    background: transparent;
    border: 0;
    /* margin-left: 9px; */
    border-radius: 3px;
}

/* .listing-table table tr th:nth-child(3) {
    width: 29%;
} */

.listing-table table tr td.action-btns {
    min-height: 71px;
}

.add-property-sc .form-group label {
    margin-bottom: 3px;
}

.multiple-imges {
    margin-bottom: 7px;
    display: table;
    width: 100%;
}

.image-pre-outer {
    display: flex;
    /* flex-wrap: wrap; */
    /* margin: 14px 0 0; */
    width: 100%;

}

.selected-images {
    border-radius: 2px;
    border: 1px dashed #e0d6d6;
    padding: 6px 12px;
}

.outer-list-pro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}

.form-group.add-image .upl-tet {
    width: 100px;
    height: 100px;
    border: 1px dashed #ddd;
    position: relative;
    background: #fff;
    font-size: 12px;
    color: #a9a9a9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    pointer-events: none;
}

.main-img-priew {
    position: absolute;
    width: 100px;
    /* border: 1px dashed #ddd; */
    padding: 5px;
    margin-right: 10px;
    border-radius: 10px;
    margin-bottom: 6px;
    height: 100px;
    top: 27px;
}

.main-img-priew p {
    position: absolute;
    top: 0;
    right: 0;
    background: #e89532;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.main-img-priew img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    min-width: 100%;
    border-radius: 6px;
}

.calander-box ul li {
    width: 14.28%;
    padding: 0px 20px 13px;
    border: 1px solid #ddd;
    border-left: 0;
    border-top: 0;
    background: #fff;
    list-style: none;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 130px;
    justify-content: flex-end;
    color: #929292;
}

.week-box ul li {
    width: 14.28%;
    padding: 0px 20px;
    text-align: center;
    border-left: 0;
    border-top: 0;
    background: #fff;
    list-style: none;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #929292;
}

/* 
.selected-box {
  background-color: #3d3d3d !important;
}

.selected-box span {
  color: white !important;
} */

.calander-box ul li span {
    position: absolute;
    top: 18px;
    font-size: 19px;
    font-weight: 500;
    color: #2f2f2f;
}

.calander-box {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    margin-bottom: 40px;
    cursor: pointer;
    user-select: none;
}

.week-box {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.calander-box ul,
.week-box ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.add-button {
    width: auto;
    height: 42px;
    border: 0;
    color: #fff;
    background: #e89532;
    font-size: 19px;
    font-weight: 600;
    border-radius: 5px;
    margin: 0 auto;
    display: table;
    padding: 0 30px;
}

.edit-price {
    position: sticky;
    top: 0;
}

.edit-price input {
    font-size: 41px;
    border: 0;
    font-weight: 800;
    text-align: center;
    width: 100%;
    outline: none;
}

.edit-price .form-group {
    margin: 40px 0;
}

.location {
    border: 1px solid #ddd;
    border-radius: 100px;
    padding: 15px 13px;
    font-size: 12px;
}

.cncel-btns {
    margin-top: 50px;
}

.cncel-btns button {
    width: 100%;
    height: 50px;
    border: 1px solid #212121;
    background: #212121;
    color: #fff;
    margin-bottom: 13px;
    border-radius: 5px;
}

.location i {
    color: #fa4d28;
    margin-right: 6px;
}

.cncel-btns button.cancel-btn {
    background: #fff;
    color: #212121;
}

.add-prop-input .form-group {
    width: 45%;
}

.add-property-sc .form-group.add-image.new-add-gallery span {
    width: 100%;
    border-radius: 4px;
    height: 160px;
    top: -5px;
    position: relative;
}

.image-pre-outer .main-img-priew {
    position: relative;
    top: 0;
}

.add-property-sc .form-group.add-image.new-add-gallery input {
    width: 100%;
    height: 160px;
}

.multiple-imges .form-group.add-image.new-add-gallery span {
    top: 5px;
}

.multiple-imges .form-group.add-image.new-add-gallery {
    width: 20%;
    float: left;
    margin-right: 15px;
}

.multiple-imges label {
    width: 100%;
}

/* .add-property-sc .form-group button {
    position: static;
    right: 10px;
    top: 4px;
    background: transparent;
    color: #fa4242 !important;
    font-size: 17px;
    padding: 6px 0 0;
    font-weight: 500;
    text-align: right;
    margin-left: auto;
    float: right;
    display: table;
} */

.side-bar ul.submenu {
    margin: 0;
}

.side-bar ul.submenu li {
    padding: 0 20px 0;
    list-style: none;
}

.side-bar ul.submenu li a {
    padding-left: 16px;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #e89532;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #e89532;
}

li.down-arow {
    position: relative;
}

li.down-arow::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid #888;
    right: 23px;
    top: 32px;
    border-top: 0;
    border-left: 0;
    transform: rotate(-45deg);
}

.side-bar ul.submenu i {
    display: none;
}

li.down-arow {
    position: relative;
}

.notification {
    position: absolute;
    right: -30px;
    transform: translateX(0%);
    min-width: 480px;
    background: #fff;
    box-shadow: 0 0 10px 1px #ddd;
    padding: 0px;
    border-radius: 10px;
    top: 32px;
    z-index: 9;
    max-height: 310px;
    overflow-y: auto;
}

.notification ul {
    padding: 0;
    margin: 0;
}

.app.active .left-sidebar li.down-arow {
    font-size: 0;
}

.about h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 29px;
    text-transform: capitalize;
}

.about h2 a {
    background: #e89532;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 6px 20px;
    border-radius: 5px;
}

.about h2 a i {
    margin-right: 5px;
}

.notification ul li {
    list-style: none;
    text-align: left;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 12px 12px;
    border-bottom: 1px solid #ddd;
}

.notfication ul li label {
    right: 13px;
    top: 36px;
    width: 10px;
    height: 10px;
    background: #ff7474;
    border-radius: 100%;
}

.notification ul li img {
    max-width: 40px;
    max-height: 40px;
    /* border-radius: 100px; */
    min-width: 40px;
    min-height: 40px;
    object-fit: cover;
    margin-right: 10px;
}

.notification a.view_all {
    color: #e89532;
    text-decoration: none;
    width: 100%;
    text-align: center;
    display: table;
    font-weight: 500;
    border-top: 1px solid #ddd;
    padding-top: 11px;
}

ul.filter-tab {
    display: flex;
    padding: 0;
    margin: 50px 0 26px;
}

.right-top-bar div h5 {
    font-size: 15px;
    margin: 0;
}

.modals ul {
    padding: 0;
    margin-bottom: 8px;
    display: flex;
}

.modals ul li:first-child {
    list-style: none;
}

.modals ul li {
    margin-right: 20px;
    padding-right: 10px;
    color: #a0a0a0;
}

.modals ul li b {
    padding-left: 6px;
}

.right-top-bar div p {
    margin: 0;
    padding-right: 25px;
}

ul.filter-tab li {
    list-style: none;
    margin-right: 20px;
}

ul.filter-tab li span.active {
    color: #fff;
    background: #e89532;
    padding: 11px 30px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #e89532;
}

ul.filter-tab li span.inactive {
    color: #e89532;
    background: #fff;
    padding: 11px 30px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #e89532;
}

.card {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    background-color: #fff;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    overflow: hidden;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: #e89532;
    border-color: #e89532;
    color: #fff !important;
}

.pagination>li>a {
    border: 1px solid #e89532;
    outline: none;
    cursor: pointer;
    border-radius: 100px;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0 0 10px 1px #c6c6c6;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #e89532;
    border-color: #e89532;
    outline: none;
}

.pagination-button {
    padding: 7px 15px !important;
    border-radius: 4px !important;
}

.pagination>li>a,
.pagination>li>span {
    color: #e89532 !important;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: 100% !important;
    font-size: 24px;
}

.chat-screen {
    height: 500px;
    width: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 20px;
}

.message-right {
    max-width: 80%;
    display: table;
    margin-left: auto;
    width: auto;
}

/* chatt screen css */
.main-chat {
    display: flex;
    background-color: #e895320d;
    border: 1px solid #ccc;
}

.left-notif {
    width: 25%;
    border-right: 1px solid #e8e6ea;
}

.right-msg {
    overflow: hidden;
    position: relative;
    /* padding: 15px 15px; */

    width: calc(100% - 25%);
}

.space-search {
    padding: 15px;
    border-bottom: 1px solid #e8e6ea;
}

.drop-t .dropdown-menu a {
    font-size: 14px;
}

.drop-t .dropdown-menu.show {
    border: 0px !important;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 9%) !important;
}

.clearfix {
    clear: both;
}

.comment-content p {
    font-size: 13px;
    margin-bottom: 5px;
    line-height: 15px;
}

.slide-comment {
    display: none;
}

.comment-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.comment-img img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.flex-line {
    display: flex;
    margin: 5px 0px;
}

.comment-content {
    width: calc(100% - 35px);
    padding-left: 6px;
}

/* search screen start */
.justify {
    justify-content: center;
}

.filter-side {
    background: #1f69ff;
    border-radius: 5px;
    width: 93px;
    height: 43px;
    display: flex;
    align-items: center;
    color: #ffffff;
    justify-content: center;
}

.filter-side img {
    padding-right: 5px;
}

.searc-outer {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.search-side {
    width: calc(100% - 93px);
    padding-left: 15px;
}

.search-side .form-control {
    width: 100%;
    height: 43px !important;
    padding: 10px 15px 10px 74px;
    background: #ffffff;
    border: 1px solid #bfbfbf !important;
    border-radius: 5px !important;
    color: #1f69ff;
    font-size: 16px !important;
}

.my-field input:focus+label.label-up {
    transform: translate(12px, -11px);
    font-size: 12px;
    background: #fff;
    padding: 0px 10px;
    color: #000 !important;
}

.sort-by {
    font-size: 16px;
    color: #000;
    line-height: 24px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.search-r2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 8px;
}

.outer-profile2 {
    width: auto;
    height: 230.05px;
    position: relative;
    border-radius: 7px;
    margin-bottom: 10px;
}

.outer-profile2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
}

.top-content {
    position: absolute;
    top: 10px;
    right: 10px;
}

.top-content p {
    font-size: 11.4146px;
    line-height: 8px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #ffffff;
    text-shadow: 0px 1.7561px 1.7561px rgba(0, 0, 0, 0.2);
}

.nav-more li a {
    font-weight: 400;
    font-size: 10.5366px;
    line-height: 8px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 3px 5px;
    border: 0.439024px solid #ffffff;
    border-radius: 8.78049px;
}

.nav-more li {
    margin: 0px 6px 7px 0px;
}

.bottom-content h2 {
    font-weight: 500;
    font-size: 15.8049px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
}

.bottom-content p {
    line-height: 13px;
    display: flex;
    font-size: 10px;
    align-items: center;
    color: #ffffff;
    padding-bottom: 4px;
}

.bottom-content {
    position: absolute;
    bottom: 4px;
    left: 7px;
}

.nav-more li.more-bb a {
    border: 0px;
    padding: 0px;
}

.nav-more {
    align-items: center;
}

.nav-more li.active a {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.nav-more li.active.more-bb a {
    background-color: transparent;
    color: #fff;
}

.m-8 {
    margin: 0px -6px;
}

.search-side .select-up i {
    right: 11px;
    top: 11px;
    font-size: 20px;
}

.chat-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #000000;
}

.inner-pages .form-control {
    width: 100%;
    height: 43px !important;
    padding: 10px;
    background: transparent;
    border: 1px solid #bfbfbf !important;
    border-radius: 0px !important;
    color: #000;
    font-size: 16px !important;
}

.pad-l {
    padding-left: 35px !important;
}

.search-pos {
    color: #bfbfbf;
    position: absolute;
    right: 14px;
    top: 17px;
}

.space-search form {
    position: relative;
}

.img-chat {
    height: 48px;
    width: 48px;
    overflow: hidden;
    border-radius: 50%;
}

.space-search button {
    position: absolute;
    top: 11px;
    right: 10px;
    background: transparent;
    border: 0;
    color: #e89532;
    display: none;
}

.img-chat img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.content-chat h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.content-chat h6 span {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: right;
    color: #adafbb;
}

.content-chat {
    width: calc(100% - 48px);
    padding-left: 15px;
}

.chat-nav li {
    width: 100%;
    border-bottom: 1px solid #e8e6ea;
    padding: 16px 15px;
}

.content-chat p {
    font-weight: inherit;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.chat-notification {
    /* height: calc(100vh - 250px); */
    height: 675px;
    overflow-x: auto;
}

.heding-top {
    font-weight: 600;
    font-size: 26px;
    line-height: 100%;
    text-align: center;
    color: #000000;
}

.left-space {
    padding-left: 25px;
    position: relative;
}

.left-arrow {
    position: absolute;
    left: 0px;
    top: 13px;
}

.profile-chat {
    height: 41px;
    width: 41px;
    overflow: hidden;
    border-radius: 50%;
}

.profile-chat img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.text-chat {
    width: calc(100% - 41px);
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left-tt h6 {
    font-weight: 500;
    font-size: 21px;
    line-height: 87%;
    display: flex;
    align-items: center;
    color: #000000;
    padding-bottom: 2px;
}

.left-tt p {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.4);
}

.chat-head {
    display: flex;
    align-items: center;
    width: 100%;
}

.day-notification {
    position: relative;
    border-bottom: 1px solid #bfbfbf;
    margin: 5px 0px 18px;
}

.day-notification p {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.7);
    position: relative;
    bottom: -9px;
    background: #fefaf5;
    display: inline-block;
    text-align: center;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 0px 15px !important;
}

.receive-msg {
    background: rgb(232, 149, 50, 0.13);
    padding: 16px;
    display: table;
    border-radius: 15px 15px 15px 0px;
    margin-bottom: 5px;
    max-width: 80%;
}

.receive-msg p {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
}

.time-msgs {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 16px;
}

.send-msg {
    background: rgba(103, 202, 244, 0.15);
    border-radius: 15px 15px 0px 15px;
    padding: 16px;
    max-width: 80%;
    margin-bottom: 5px;
    display: table;
    margin-left: auto;
}

.send-msg p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 5px;
    color: #000000;
}

.input-footer {
    position: relative;
    /* width: calc(100% - 50px);
    padding-right: 15px; */
    width: 100%;
}

.footer-voice {
    display: flex;
    align-items: center;
    justify-content: center;
}

.v-footer {
    width: 50px;
}

.chat-footer input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.4);
}

/* .chat-footer{    display: flex;
  align-items: center;
  justify-content: center;} */

.chat-footer input {
    font-size: 14px;
    padding-right: 40px !important;
    width: 100%;
    height: 42px;
    border: 0;
    background: transparent;
}

.footer-sec button {
    position: absolute;
    top: 26px;
    right: 21px;
    background: transparent;
    border: 0;
    color: #e89532;
}

.send-chat-btn {
    position: absolute;
    right: 14px;
    top: 10px;
}

.main-chat p,
.main-chat h6,
.main-chat h5,
.main-chat h4,
.main-chat h3,
.main-chat h2 {
    padding: 0px;
    margin: 0px;
}

.main-chat a {
    text-decoration: none;
}

.header-main {
    background-color: transparent;
    /* position: fixed; */
    /* top: 0px; */
    width: 100%;
    z-index: 99;
    border-bottom: 1px solid #d3d3d3;
    padding: 19px 20px;
}

.footer-sec {
    height: 70px;
    background-color: #fefaf5;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.15);
    /* padding: 30px; */
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    padding: 15px;
}

.content-sec {
    padding: 15px 15px 70px 15px;
    height: 687px;
    overflow-y: auto;
}

.text-right {
    text-align: right !important;
}

button.btn-line.dropdown-toggle {
    border: 0px;
    background: transparent;
    appearance: none;
}

.drop-t .dropdown-toggle::after {
    border: 0px;
}

.filter-main {
    display: flex;
    justify-content: space-between;
    padding: 0px 0 15px;
    width: 100%;
}

.filter-left {
    display: flex;
}

.filter-left .form-group span.search-c {
    position: absolute;
    left: 10px;
    top: 10px;
}

.filter-left .form-group input {
    padding-left: 40px;
    height: 45px;
}

.filter-left .form-group button.search-button {
    right: 0;
    top: -1px;
    border-radius: 60px;
    background: #e89532;
    color: #fff;
    padding: 5px 9px;
    margin: 0;
}

.filter-left .form-group span.filer-iic {
    position: absolute;
    left: 10px;
    top: 10px;
}

.filter-left .form-group select {
    padding-left: 33px;
}

.filter-left .form-group {
    position: relative;
}

.footer-voice form {
    display: flex;
    width: 100%;
}

.filter-main input {
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 15px;
    min-width: 300px;
}

select {
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 15px;
    /* color: #8a8a8a; */
}

.filter-right {
    margin-left: 12px;
}

.cal-in {
    position: sticky;
    top: 0;
}

.space-search input {
    border: 1px solid #ddd;
    width: 100%;
    height: 49px;
    border-radius: 5px;
    padding: 0 18px;
}

.check-box-flei label.checkbox-inline input {
    width: auto;
    height: auto;
    margin-right: 10px;
}

.check-box-flei label.checkbox-inline {
    display: flex;
    width: 100%;
}

.vendor-listing ul {
    display: flex;
    justify-content: center;
    /* margin-bottom: 30px; */
    padding: 0;
}

.vendor-listing ul li {
    margin: 0 6px;
    list-style: none;
}

.vendor-listing ul li span {
    border: 1px solid #e89532;
    color: #e89532;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 21px;
    border-radius: 5px;
}

.vendor-listing ul li span.active {
    background: #e89532;
    color: #fff !important;
    font-weight: 700;
}

.caht-head {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 0px 24px 16px;
    justify-content: space-between;
    border-radius: 0;
    border-bottom: 1px solid #e89532;
}

.detils h4 {
    margin: 0;
    font-size: 17px;
}

.caht-head i {
    font-size: 21px;
    color: #707070;
}

.rese-deails ul {
    padding: 0;
}

.caht-head span {
    font-size: 13px;
    color: #767676;
}

.left-chat-hd {
    display: flex;
    align-items: center;
}

.caht-head ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.rese-deails ul li label {
    font-style: italic;
    font-weight: 600;
    min-width: 121px;
}

.rese-deails ul li {
    list-style: none;
    margin-top: 9px;
    font-size: 17px;
}

/* end */

.rad {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.rad input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.rad:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.rad input:checked~.checkmark {
    background-color: #e89532;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.rad input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.rad .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.ficed-top-nbar {
    display: flex;
    /* position: sticky; */
    justify-content: space-between;
    top: 0;
    /* background: #fff; */
    padding: 10px 0;
    z-index: 1;
}

.cat-prop .list-pro {
    min-width: 330px;
    z-index: 99;
    background: #fff;
    border-radius: 10px;
    padding: 20px 15px;
    position: absolute;
    top: 59px;
    right: 0;
    border: 1px solid #ddd;
    max-height: 400px;
    overflow-y: auto;
}

.cat-prop .list-pro ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.cat-prop .list-pro ul li div {
    display: flex;
    align-items: center;
}

.cat-prop .list-pro ul li div img {
    margin-right: 10px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.cat-prop .list-pro ul li:last-child {
    margin: 0;
}

.cat-prop .list-pro ul li .text-prop {
    flex-direction: column;
    align-items: flex-start;
}

.cat-prop .list-pro ul {
    padding: 0;
    margin: 0;
}

.cat-prop button {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 100px;
    padding: 7px 40px 7px 7px;
    position: relative;
}

.calander-right {
    border-left: 1px solid #ddd;
    padding-left: 24px;
    height: 100%;
    position: sticky;
    top: 0;
}

.per-night-price {
    border: 1px solid #bababa;
    padding: 11px;
    border-radius: 10px;
    margin: 14px 0 19px;
}

.per-night-price h4 {
    margin: 13px 0 0;
    font-weight: 700;
}

.discounts {
    border-top: 1px solid #ddd;
    margin-top: 40px;
    padding-top: 28px;
}

.customer-pricing p {
    color: #000;
    align-self: center;
    margin-bottom: 0;
    cursor: pointer;
    text-decoration: underline;
}

.customer-pricing {
    border: 1px solid #bababa;
    border-radius: 10px;
    margin: 14px 0 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 16px;
}

.customer-pricing h6 {
    margin: 0;
}

.discounts p {
    font-size: 13px;
    color: #aeaeae;
}

.per-night-price p {
    margin: 0;
    font-size: 13px;
    color: #b4b4b4;
}

.top-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.calander-box ul li.disable {
    background: #efefef;
    cursor: not-allowed;
}

.cat-prop .list-pro ul li .text-prop h6 {
    margin: 0;
}

.cat-prop .list-pro ul li .text-prop p {
    margin: 0;
    font-size: 11px;
    text-align: left;
}

.aminity-box {
    border: 1px solid #ddd;
    margin: 10px 0;
    border-radius: 5px;
    padding: 17px;
    display: flex;
    flex-wrap: wrap;
}

.aminity-box .d-flex {
    width: 33.33%;
    text-align: left;
    margin-bottom: 13px;
}

.aminity-box .d-flex input {
    width: auto;
    height: auto;
    margin-right: 8px;
}

.aminityi {
    width: 100%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex-wrap: wrap;
    padding: 9px;
    min-height: 52px;
}

.react-select__input {
    height: 32px !important;
}

.aminityi div {
    margin-right: 4px;
    background-color: #DCD9D9;
    font-size: 15px;
    margin-bottom: 3px;
    align-self: center;
    padding: 3px 8px;
    border-radius: 4px;
}

.cat-prop button img {
    border-radius: 100px;
    max-height: 35px;
    max-width: 35px;
    object-fit: cover;
    margin-right: 10px;
}

.cat-prop button::before {
    content: "";
    position: absolute;
    right: 14px;
    top: 18px;
    width: 8px;
    height: 8px;
    border: 2px solid #c0c0c0;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
}

.cat-prop {
    position: relative;
}

.calander-top h4 {
    font-size: 19px;
}

.calander-top p {
    font-size: 13px;
}

.disabled-locations {
    color: red !important;
    font-size: 13px;
}

.outre-calnder-grid-outer::-webkit-scrollbar,
.rooms-outer::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.outre-calnder-grid-outer::-webkit-scrollbar-track,
.rooms-outer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.outre-calnder-grid-outer::-webkit-scrollbar-thumb,
.rooms-outer::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 10px;
}

/* Handle on hover */
.outre-calnder-grid-outer::-webkit-scrollbar-thumb:hover,
.rooms-outer::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

.lower-calander-data {
    margin-top: 30px;
}

.top-link-calander {
    display: flex;
    justify-content: space-between;
}

.top-link-calander h4 {
    font-size: 20px;
}

.top-link-calander h4 span {
    color: #8d8d8d;
    font-size: 16px;
}

.romstarus {
    display: flex;
}

.border-right-0 {
    border: 0;
}

.room-sell label {
    border: 1px solid #e0e0e0;
    padding: 9px;
    min-width: 180px;
    font-size: 12px;
    min-height: 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rooms-outer {
    overflow: hidden;
}

.room-sell.romstarus span {
    background: #c6f1c9;
    border-radius: 100px;
    padding: 5px 20px;
    width: 100%;
    margin-left: 10px;
}

.room-sell {
    display: flex;
    align-items: center;
}

label.mg-n {
    margin-top: 22px;
    min-width: 180px;
}

.room-rount {
    border: 1px solid #e0e0e0;
    padding: 9px 5px;
    font-size: 13px;
    min-width: 60px;
    min-height: 62px;
    height: 62px;
}

#calendar-price .rad .checkmark:after {
    top: 5px;
    left: 5px;
}

#calendar-price label.rad {
    font-size: 16px;
    margin-right: 30px;
    padding-left: 28px;
}

#calendar-price span.checkmark {
    height: 18px;
    width: 18px;
    top: 2px;
}

#calendar-price button.btn.btn-primary {
    background: #e89532;
    border: 0;
}

div#calendar-price p {
    margin: 8px 0;
}

span.sech-btn {
    background: #e89532;
    padding: 7px 16px;
    border-radius: 5px;
    color: #fff;
}

.ficed-top-nbar input {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 100px;
    padding: 7px 10px 7px 16px;
    position: relative;
}

.room-rount-header {
    /* border: 1px solid #e0e0e0; */
    font-size: 13px;
    text-align: right;
    min-width: 60px;
    min-height: 62px;
}

.gray--first {
    padding: 25px 0;
}

.vendor-listing {
    max-width: 90%;
    margin: 0 auto;
    /* background-color: white; */
}

.gray-clr {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    background: #FFECD5;
    width: 100%;
}

form.promo-form input,
form.promo-form textarea {
    width: 100%;
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 12px;
}

form.promo-form .form-group {
    margin-bottom: 17px;
}

form.promo-form button[type=submit] {
    background: #e89532;
    border: 0;
    color: #fff;
    padding: 10px 40px;
    border-radius: 4px;
}

span.side-br-mobile {
    display: none;
}

.react-datepicker-wrapper {
    width: 100%;
}

form.promo-form textarea {
    height: 100px;
}

.available-booking-box {
    min-width: 60px;
    min-height: 62px;
    padding: 9px;
    border: 1px solid #e0e0e0;
}

.room-sell label span {
    display: block;
    font-size: 14px;
    color: var(--bs-link-color);
}

.room-rount span {
    display: block;
    font-size: 11px;
    color: #707070;
}

.room-rount-header span {
    display: block;
    font-size: 13px;
    color: #707070;
}

.rooms-count {
    display: flex;
}

.cursor-pointer {
    cursor: pointer;
}

.selected-left-box {
    border-left: 2px solid black !important;
    border-top: 2px solid black !important;
    border-bottom: 2px solid black !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.selected-box {
    border-top: 2px solid black !important;
    border-bottom: 2px solid black !important;
    border-radius: 0px !important;
}

.selected-right-box {
    border-right: 2px solid black !important;
    border-top: 2px solid black !important;
    border-bottom: 2px solid black !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.available-bookings {
    width: 0px !important;
    height: 0px !important;
    visibility: hidden;
}

.disable {
    background: #efefef;
    cursor: not-allowed;
}

.room-sell .room-label {
    width: 180px;
    font-size: 12px;
}

.remove-images {
    margin-bottom: 0px;
    background-color: transparent !important;
    color: #fa4242 !important;
}

.dasboad-property-detail {
    background-color: white;
    padding: 20px;
}

.search-button {
    border: 0px;
}

.paginationContainer {
    padding: 8px 0px;
    /* background-color: white;
    box-shadow: 0px 0px 12px 2px rgba(173, 173, 173, 1); */
    border-radius: 7px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    /* width: 30%; */
    margin: auto;
}

.property-list-out {
    max-width: 100%;
    margin: 0 auto;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered {
    position: absolute;
    right: 0;
    margin: 0;
    display: table;
    transform: translateY(-50%) !important;
    width: 100%;
    top: 50%;
    left: 0;
    margin: 0 auto;
    height: auto;
    min-height: inherit;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content {
    border: 0;
    height: 100%;
    min-width: 620px;
    box-shadow: 0 0 10px 1px #ddd;
    height: auto;
    overflow-y: auto;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content .drop-image-outer span {
    background: #fff;
    position: relative;
    z-index: 9;
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    border-radius: 10px;
    justify-content: center;
    pointer-events: none;
    align-items: center;
    color: #e89532;
}

.product-slider-main .listing-table-scrl table tr th:nth-child(2) {
    width: 43%;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content .drop-image-outer {
    border: 1px dashed #ddd;
    border-radius: 7px;
    padding: 31px 0;
    position: relative;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content .drop-image-outer input {
    font-size: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 30px;
    top: 0;
}

.addpadding input {
    padding-right: 80px;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content input {
    background: #fff;
}

.btnedits {
    position: absolute;
    top: 5px;
    right: 18px;
}

.outer-btn-div {
    position: relative;
}

.dasboad-property-detail .modal-body .mb-3:last-child {
    border: 0;
}

.outer-btn-div {
    position: relative;
}

.show-all {
    position: absolute;
    bottom: 20px;
    left: 15px;
    width: 96%;
    height: auto;
    background: rgba(250, 242, 233, .76);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    min-height: 137px;
    right: 0;
    border-radius: 10px;
    cursor: pointer;
}

.show-all p {
    font-weight: 600;
    margin: 0
}

.sub-tag {
    background: #e895324a;
    padding: 7px 10px !important;
    border-radius: 100px;
    margin: -5px 5px 0;
}

.listing-table p {
    font-weight: 600;
}

.sub-cat-brrd h5 {
    font-size: 14px;
    padding: 7px 0px;
    font-weight: 400;
}

.sub-cat-brrd h6 {
    font-size: 16px;
}

.dasboad-property-detail .show {
    background: #00000042;
}

.sub-cat-brrd ul li::before {
    position: absolute;
    top: 50%;
    left: -39px;
    width: 11px;
    height: 11px;
    background: #000;
    content: "";
    border-radius: 100px;
    transform: translateY(-50%);
}

.sub-cat-brrd ul li {
    list-style: none;
    position: relative;
    margin-bottom: 11px;
}

.sub-cat-brrd ul::before {
    position: absolute;
    width: 3px;
    height: 62%;
    content: "";
    background: black;
    left: -3px;
    top: 7px;
}

.sub-cat-brrd ul {
    padding-bottom: 0;
    position: relative;
    margin-bottom: 27px;
}

.sub-cat-brrd ul:last-child {
    border-bottom: 0px solid #ddd;
}

.dasboad-property-detail .modal-dialog.modal-dialog-centered .modal-content select {
    width: 100%;
}

.modal-body label {
    font-size: 15px;
}

.dasboad-property-detail .modal-body .mb-3 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 22px;
}

.modal-body input {
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 13px;
    padding: 0 15px;
}

/* .form-group.description {
    max-height: 500px;
    overflow-y: auto;
} */

.proety-top {
    margin-bottom: 26px;
}
.w-md-editor-area {
    overflow: auto;
    border-radius: 5px;
}
.form-group.description .h-100.wmde-markdown-var.w-md-editor.w-md-editor-show-edit {
    width: 75vw;
    height: 35vh !important;
}
.pro-featu ul li:last-child::before {
    opacity: 0;
}
.dasboad-property-detail .modal-body {
    max-height: 500px;
    overflow-y: auto;
}
.detailsbook ul {
    padding: 0;
    margin: 0;
    background-color: white;
    box-shadow: 0 0 10px 1px #ddddddd9;
    border-radius: 10px;
}

.detailsbook ul li {
    list-style: none;
    padding: 17px 23px;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    font-size: 15px;
}

.detailsbook ul li:last-child {
    border-bottom: 0px solid #efefef;
}

.detailsbook ul li span {
    font-weight: 600;
    min-width: 175px;
    /* font-style: italic; */
}

.detailsbook p {
    margin: 13px 0 19px;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
}



#chat3 .form-control {
    border-color: transparent;
    height: 46px;
    border: 1px solid #ddd;
}

.rounded.mb-3 {
    position: relative;
}

#chat3 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
}

span#search-addon {
    position: absolute;
    background: transparent;
    top: 10px;
    right: 10px;
    color: #a3a3a3;
}

.left-chat li {
    list-style: none;
}

.badge-dot {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-left: 2.9rem;
    margin-top: -0.75rem;
}

.left-chat {
    background: #fff;
    padding: 16px;
    border-radius: 10px;
}

.left-chat .fw-bold {
    font-weight: 600 !important;
    color: #595959;
}

.left-chat a {
    text-decoration: none;
}

.rounded-3.bg-primary {
    background: #ffdaae !important;
    color: #000 !important;
    max-width: 73%;
    margin-left: auto !important;
    padding: 12px 20px !important;
    border-radius: 6px 6px 0 6px !IMPORTANT;
    display: table;

}

input#exampleFormControlInput2 {
    font-size: 16px;
    background: #fff;
    outline: none;
}

/* .active {
    color: #e89532 !important;
} */

.message-box {
    position: relative !important;
    height: calc(100vh - 320px) !important;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.chat-information {
    font-size: 20px;
}


.reservation-message-box {
    position: relative !important;
    height: calc(100vh - 370px) !important;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.no-property,
.loading-text {
    height: 78vh;
}

.no-property i {
    font-size: 200px;
    margin-bottom: 20px;
    color: #e89532;
    /* width: 100px; */
}

.loading-text h5 {
    color: #e89532;
    font-size: 25px;
}

.removeAll {
    cursor: pointer;
    color: white;
    border: none;
    padding: 6px 10px;
    clear: both;
    position: inherit;
    margin: 10px 0px;
    display: block;
    float: right;
}

.senderImage {
    width: 45px;
    height: 40px;
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 8px;
}

.description.d-flex {
    display: flex;
    align-items: center;
}

.loc .fa-pencil-alt,
.name .fa-pencil-alt,
.description .fa-pencil-alt {
    font-size: 11px;
    border: 1px solid #ddd;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

body .wmde-markdown {
    background: transparent;
    color: #4d4d4d;
    font-family: "Poppins", sans-serif !important;
}

.bathroomTypeIndex {
    width: 5%;
}

.product-slider-main h4 {
    color: #4e4439;
    font-family: Glitten;
    font-size: 150%;
    font-weight: 600;
    margin: 13px 0 3px;
}

.pro-featu ul {
    display: flex;
    padding: 3px 0 18px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgb(232, 151, 50);
}

.pro-featu ul li {
    color: #4e4439;
    margin: 0;
    white-space: nowrap;
    font-family: Campton-light;
    list-style: none;
    position: relative;
    margin-right: 28px;
}

.pro-featu ul li::before {
    position: absolute;
    right: -16px;
    top: 9px;
    width: 3px;
    height: 3px;
    background: #404040;
    content: "";
    border-radius: 100px;
}

h5.loc img {
    height: 20px;
    position: relative;
}

.description h5 {
    font-weight: 500;
    position: relative;
    font-family: Campton-medium !important;
    font-size: 1rem;
    margin: 0;
}

.meta-desciption p {
    color: #000;
    font-family: Campton-light;
    font-weight: 500;
    position: relative;
    white-space: pre-line;
    display: block;
    font-size: .875em;
    margin-bottom: 0 !important;
    margin-top: 0;
    overflow: auto;
}

.listing-table-scrl ul {
    padding: 0;
    margin: 0;
}

.listing-table-scrl ul li {
    color: #000;
    font-family: Campton-light;
    font-weight: 500;
    position: relative;
    white-space: pre-line;
    display: block;
    font-size: .875em;
    margin-bottom: 0 !important;
    margin-top: 0;
    overflow: auto;
}

div#calendar-price .modal-body {
    max-height: 500px;
    overflow-y: auto;
}

.main-room-slider .slick-slide {
    padding-right: 16px;
}

.inner-tab-deisign {
    box-shadow: 0 0 10px 1px #ddd;
    padding: 7px;
    border-radius: 10px;
    margin-bottom: 33px;
    transition: 0.5s;
    position: relative;
    background: #fff;
}

.inner-tab-deisign:hover {
    transition: 0.5s;
    position: relative;
    /* transform: translateY(-10px); */
    transform: scale(1.03);
}

.top-card-had img {
    max-width: 100%;
    min-height: 230px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 12px;
    max-height: 230px;
    width: 100%;
}

.top-card-had h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 87%;
}

.message-box div {
    width: 100%;
}

.left-chat li .d-flex.flex-row .pt-1 p.small.text-muted {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 230px;
    height: 1.2em;
    white-space: nowrap;
}

.top-card-had {
    align-items: center;
    padding: 9px 10px 15px;
}

.locaton-card p img {
    margin-right: 4px;
    max-width: 21px;
    min-height: auto;
    max-height: inherit;
}

.locaton-card p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    width: 100%;
    opacity: 1;
    font-size: 15px;
    color: #fff;
}


.listing-table.delete-tab {
    display: none;
}

.new-tab-design {
    padding: 22px 0 0;
}

.rf-left h6 {
    margin: 0;
    color: #000;
    font-size: 14px;
}

.rf-left span {
    color: #e89532;
    font-weight: 600;
    font-size: 15px;
    padding-left: 5px;
}

.action-btn button img {
    width: auto;
    height: auto;
    min-height: auto;
    max-height: initial;
    margin: 0;
}

.inner-tab-deisign:hover .overlay {
    transform: scale(1);
    transition: 0.5s;
}

.overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 97%;
    background: #0009;
    left: 0;
    border-radius: 10px;
    display: flex;
    transition: 0.5s;
    transform: scale(0);
    justify-content: center;
}

button.delet-btn {
    background: #ffffff82;
    border: 0;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin-right: 13px;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
}

button.view-btn {
    background: #ffffff82;
    border: 0;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin-right: 13px;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
}

.action-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.locaton-card {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: #e89532cf;
    padding: 8px 14px;
    border-radius: 3px;
    border: 1px solid #ddd;
    width: 50%;
    float: left;
}

.refrela {
    padding: 0 10px;
}

.left-slider {
    width: 100%;
    margin-right: 0px;
    position: relative;
}

.left-slider button.slick-arrow {
    display: none !important;
}

.inner-tab-deisign .bed-rooms {
    padding: 0 0 0px;
    border-bottom: 1px solid #ddd9;
    margin-bottom: 12px;
}

.loc-left {
    width: 100%;
    padding: 9px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #CCC;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #E89532;
}

input:focus+.slider {
    box-shadow: 0 0 1px #E89532;
}

input:checked+.slider:before {
    transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.inner-tab-deisign .bed-rooms ul {
    justify-content: flex-start;
    padding-left: 11px;
}

.inner-tab-deisign .bed-rooms ul li {
    display: flex;
    align-items: center;
    margin: 0 7px 0 0;
    font-size: 14px;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    padding: 9px 8px;
}

.inner-tab-deisign .bed-rooms ul li img {
    max-width: 26px;
    opacity: 0.8;
    margin-right: 6px;
    max-height: 20px;
    object-fit: contain;
    border-right: 1px solid #ddd;
    padding-right: 8px;
    min-height: 18px;
}

.inner-tab-deisign .bed-rooms ul li {
    display: flex;
    align-items: center;
    margin: 0 7px 0 0;
    font-size: 14px;
    width: 31.5%;
}

.inner-tab-deisign .bed-rooms ul li:last-child {
    margin: 0;
}

.rf-left {
    display: flex;
    align-items: center;
    padding-bottom: 13px;
}

.chat-icon {
    width: 160px;
    height: 160px;
}

/*--new-css--*/
.detail-box {
    border: 1px solid #ddd;
    background: #fff;
}

.chat-otr {
    background: #fff;
    border: 1px solid #ddd;
    padding: 17px 0;
}

.text-muted.d-flex.justify-content-start.align-items-center.pe-3.pt-3.mt-2 {
    padding: 0 20px !important;
}

.right-pro-del img {
    max-width: 50px;
    border-radius: 100%;
    object-fit: cover;
    max-height: 50px;
    min-height: 50px;
}

.pro-detail-mg {
    border-bottom: 1px solid #ddd;
    padding: 20px 30px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left-pro-det {
    width: 77%;
}

.left-pro-det h6 {
    color: #3d89ff;
    font-size: 14px;
}

.left-pro-det h5 {
    font-size: 16px;
    margin: 0;
}

.left-pro-det p {
    margin: 0;
    font-size: 13px;
}

.check-date {
    padding: 20px 30px 16px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-right: 90px;
}

.check-date::before {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 70%;
    content: "";
    top: 50%;
    background: #ddd;
}

.due-date {
    padding: 20px 30px 16px;
    border-bottom: 1px solid #ddd;
}

.due-date ul {
    padding: 0;
    margin: 0;
}

.due-date ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    padding: 2px 0;
}

.check-date span {
    font-size: 13px;
    margin: 0;
}

.check-date h5 {
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}

.check-date h6 {
    font-size: 12px;
    color: #818181;
    margin: 4px 0 0;
}

.main-room-slider .slick-list {
    padding: 10px 10px;
}

.listing-table table tr td i {
    color: #e89532;
    font-size: 18px;
}