.tabContainer {
    box-shadow: 0px 0px 12px 2px rgba(173, 173, 173, 1);
    padding: 0px 12px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: white;
}

.arrivalTab {
    border-bottom: 3px solid #e89532;
    border-bottom-left-radius: 10px;
}

.depatureTab {
    border-bottom: 3px solid #e89532;
}

.stayTab {
    border-bottom: 3px solid #e89532;
    border-bottom-right-radius: 10px;
}

.userName {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 600;
    margin-left: 5px;
}

.userImage {
    width: 50px;
    height: 50px;
}

.mobileNo {
    margin-bottom: 8px;
    font-size: 15px;
}

.mobileNo i {
    font-size: 18px;
    margin-right: 9px;
    color: #e89532;
}

.email {
    margin-bottom: 8px;
    font-size: 15px;
    margin-top: 10px;
}

.email i {
    font-size: 18px;
    margin-right: 9px;
    color: #e89532;
}

.bookingCard {
    background-color: #F7F9FB;
    width: 100%;
    padding: 0px 30px;
    margin-bottom: 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 2px rgb(239 239 239);
    cursor: pointer;
}

.bookingCard:hover {
    box-shadow: 0px 0px 12px 2px rgb(207 203 203);
}

.promotion {
    color: #02770a;
    font-weight: 500;
    background-color: #d1e7d3;
    padding: 5px 10px;
    border-radius: 5px;
}

.checkInDates,
.members {
    margin-bottom: 0px;
    color: grey;
}

.checkInDates {
    font-size: 16px;
    font-weight: 500;
}

.property {
    margin-bottom: 0px;
    letter-spacing: 0.5px;
    font-size: 18px;
    font-weight: 600;
}

.members {
    font-size: 13px;
}

.vouchers {
    font-size: 15px;
    font-weight: 600;
}

.price {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 18px;
}

.createdAt {
    font-size: 14px;
    margin-bottom: 0px;
}

.paymentRequest {
    margin: 0px 10px;
    font-weight: 600;
}

.filterDate {
    border: 1px solid #ddd;
    border-radius: 7px;
    margin-left: 5px;
    padding: 6px 20px 6px 10px;
    color: #8a8a8a;
    background-color: white;
    /* height: 38px; */
}

.paid {
    margin-bottom: 0px;
    background-color: rgb(95, 187, 95);
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
}
.vendor-listing {
    max-width: 90%;
    margin: 0 auto;
}
.pending {
    margin-bottom: 0px;
    background-color: rgb(235, 87, 51);
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
}

.propertyList {
    width: 220px;
    margin-left: 5px;
}

.carList {
    width: 168px;
    margin-left: 5px;
}

.noBookingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noBookingContainer p {
    font-size: 40px;
}

.loadingBooking {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 20vh);
}

.loadingBooking p {
    font-size: 25px;
    color: #e89532;
    font-weight: 600;
}