.tabPanel {
    display: flex;
    background-color: #f9f7f7;
    border-radius: 5px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #e89532;
}

.bookingNo {
    cursor: pointer;
    color: #E89532;
}


/* 
.tab:hover {
    font-weight: 400;
    background-color: white;
    border: 1px solid #E89532;
    border-radius: 5px;
} */

.activeTab {
    background-color: #E89532;
    border-bottom: 1px solid #E89532;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
}

.generateInvoice {
    margin-left: auto;
    float: right;
    background: #e89532;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    padding: 11px 20px;
    margin-right: 4px;
    border-radius: 5px;
    margin-bottom: 20px;
    outline: none;
    border: 0px;
}

.totalAmount {
    font-weight: 700;
}

.totalAmountTab {
    background-color: #f9f7f7;
}

.generateInvoicedata {
    margin-left: auto;
    float: right;
    font-size: 16px;
    text-decoration: none;
    padding: 11px 20px;
    margin-right: 4px;
    border-radius: 5px;
    margin-bottom: 20px;
    outline: none;
    border: 0px;
    background: #fff;
    border: 1px solid #e89532;
    color: #e89532;
}