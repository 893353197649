.remainingCharacter {
    font-size: 12px;
    color: green;
    margin-bottom: 0px !important;
}

.remainingCharacter span {
    color: black;
    font-weight: 600;
    margin-left: 4px;
}

.closeButton {
    font-size: 12px;
    height: 15px;
    width: 15px;
}

.selectArrow {
    position: absolute;
    right: 15px;
    top: 15px;
}

.descriptionEditor {
    width: 75vw;
    height: 50vh !important;
}

.featuresBody {
    height: 65vh;
    overflow: auto;
    width: 70vw;
}

.amenitiesBody {
    height: 50vh;
    overflow: auto;
}

.chk {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chk input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.chk:hover input~.checkmark {
    background-color: #ccc;
}

.chk input:checked~.checkmark {
    background-color: #282828;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.chk input:checked~.checkmark:after {
    display: block;
}

.chk .checkmark:after {
    left: 9px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}