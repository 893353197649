.selectedDate {
    background-color: #3498db;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.promotionName {
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 3px;
}

.promotionHeading {
    margin-bottom: 0px;
    font-weight: 600;
}

.promotionSubHeading {
    font-size: 14px;
}

.promotionDetailHeading {
    font-size: 20px;
    font-weight: 700;
}

.promotionDetailSubHeading {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 4px;
}

.promotionDetailInfo {
    font-size: 14px;
}

.promotionCard {
    border-radius: 6px;
    box-shadow: 0px 0px 12px 1px rgba(232,223,232,1);
}